import React from 'react'
import {Toolbar as MuiToolbar} from "@mui/material";

function Toolbar(props) {
    const { children } = props;
    return (
        <MuiToolbar>
            {children}
        </MuiToolbar>
    )
}

export default Toolbar