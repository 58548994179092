import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {getResources, createResource, updateResource, deleteResource} from '../../redux/actions/resources';
import ResourcesTable from "./Table/ResourcesTable";
import { Grid, Button, TextField, Typography} from '@mui/material';
import Modal from "../../components/Elements/Modal";
import Form from "../../components/Form/Form";

function Resources () {
  const dispatch = useDispatch();
  const { errorSnackbarMessage,successSnackbarMessage,snackbarOpen } = useSelector(state => state.snackbar);

  const [view, setView] = useState('create');
  const [openModal, setOpenModal] = useState(false);
  const [resource, setResource] = useState({id: '', name: ''});

  const handleOpenModal = () => setOpenModal(true); 
  const handleCloseModal = () => {
      setOpenModal(false); 
  }

  function onNameChange(e) {
    setResource({ ...resource, name: e.target.value });
  };

  function onAddButtonClick(value){
    setResource({name: ''});
    setView('create');
    handleOpenModal();
  }

  function onEditButtonClick(value){
    setResource(value);
    setView('edit');
    handleOpenModal();
  }

  function onDeleteButtonClick(value){
    setResource(value);
    setView('delete');
    handleOpenModal();
  }

  async function onFormSubmit() {
    let payload;
      if(view === 'create'){
       payload = { name: resource.name };
       dispatch(createResource(payload));
      }else if(view === 'edit'){
       payload = { name: resource.name };
       dispatch(updateResource(resource.id, payload));
      }else if(view === 'delete'){
        dispatch(deleteResource(resource.id));
      }
  };

  useEffect(() => {
    dispatch(getResources());
  }, [dispatch])

  useEffect(() => {
    if(errorSnackbarMessage === '' && successSnackbarMessage !== ''){
      handleCloseModal()
    }
  }, [errorSnackbarMessage,successSnackbarMessage,snackbarOpen])


  return (
    <>
      <Grid container alignItems="center" justifyContent="center" >
        <Grid item xs={12}>                     
          <ResourcesTable onAddButtonClick={onAddButtonClick} onEditButtonClick={onEditButtonClick} onDeleteButtonClick={onDeleteButtonClick} />
        </Grid>
      </Grid>
      <Modal open={openModal} handleClose={handleCloseModal}>
        <Form onSubmitFunc={onFormSubmit}>
        { view === 'delete' ? 
          (
            <>
              <Grid item xs={12}>
                  <Typography variant="h6" component="p">Confirm action</Typography>
                  <Typography variant="body2" component="p">Are you sure you want to delete the resource '{resource.name}' ?</Typography>
                </Grid>
              <Grid item xs={6}>
                <Button variant="outlined" color="error"  size="large" fullWidth onClick={handleCloseModal}>Cancel</Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="error" size="large" type="submit"  fullWidth>Delete</Button>
              </Grid>
            </>
          ) : 
          (
            <>
              <Grid item xs={12}>
                <Typography variant="body2" component="p"> { view === 'create' ? `Create resource`: `Edit resource`}</Typography>
              </Grid>
              <Grid item xs={12}>
                  <TextField name="resourceName" variant="outlined" label="Name" placeholder="Enter resource name" fullWidth autoFocus required value={resource.name} onChange={(e) => onNameChange(e)} />
              </Grid>
                  { view === 'create' && 
                    <>
                      <Grid item xs={6}>
                        <Button variant="outlined" color="primary"  size="large" fullWidth onClick={handleCloseModal}>Cancel</Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" color="primary" size="large" type="submit" fullWidth>Create</Button>
                      </Grid>
                    </>
                  }
                  { view === 'edit' && (
                    <>
                      <Grid item xs={6}>
                        <Button variant="outlined" color="primary"  size="large" fullWidth onClick={handleCloseModal}>Cancel</Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" color="primary" size="large" type="submit" fullWidth>Update</Button>
                      </Grid>
                    </>
                  )}
              </>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default Resources;