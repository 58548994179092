import React from 'react';
import {  Grid } from "@mui/material";

function Form(props) {
    const { children, onSubmitFunc } = props;

    function onSubmit(e) {
        e.preventDefault();
        onSubmitFunc();
    };


    return (
        <>
            <form autoComplete='off' onSubmit={onSubmit}>
                <Grid container spacing={1}>
                    {children}
                </Grid>
            </form>
        </>
    )
}

export default Form