import {snackbar_success, snackbar_error, snackbar_warning,  snackbar_clear} from '../actions/actionTypes';

export default function SnackbarReducer (state = {}, action) {
    switch (action.type) {
      case snackbar_success:
        return {
          ...state,
          snackbarOpen: true,
          errorSnackbarMessage: '',
          warningSnackbarMessage: '',
          successSnackbarMessage: action.message
        };
      case snackbar_error:
        return {
          ...state,
          snackbarOpen: true,
          successSnackbarMessage: '',
          warningSnackbarMessage: '',
          errorSnackbarMessage: action.message
        };
      case snackbar_warning:
        return {
          ...state,
          snackbarOpen: true,
          successSnackbarMessage: '',
          errorSnackbarMessage: '',
          warningSnackbarMessage: action.message
        };
      case snackbar_clear:
        return {
          ...state,
          warningSnackbarMessage: '',
          errorSnackbarMessage: '',
          successSnackbarMessage: '',
          snackbarOpen: false,
        };
      default:
        return state;
    }
};