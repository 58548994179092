import React, {Component} from 'react'
import Scheduler, {SchedulerData, ViewTypes, CellUnits} from 'react-big-scheduler'
import withDragDropContext from './withDnDContext'
import 'react-big-scheduler/lib/css/style.css'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import {processData} from './data';
import moment from "moment";
import {Tooltip, IconButton} from '@mui/material';
import Icon from '../Elements/Icon';

class Chronogram extends Component{
    constructor(props){
        super(props);
        
        let data = processData(props.data, props.columnKey);

        let today = moment().format('YYYY-MM-DD');
        
        let schedulerData = new SchedulerData(today, ViewTypes.Custom2, false, false, {

            schedulerMaxHeight: 0,
            tableHeaderHeight: 40,
            customResourceTableWidth: 160,
            customCellWidth: 120,
            eventItemLineHeight: 40,
            eventItemHeight: 35,
            headerEnabled: false,

            movable: props.movable,
            startResizable: props.resizable ? props.resizable.start : false,
            endResizable: props.resizable ? props.resizable.end : false,
            creatable: props.creatable,
            scrollToSpecialMomentEnabled: true,

            resourceName: "Name",
        
            views: [
                {viewName: 'Two months', viewType: ViewTypes.Custom2, showAgenda: false, isEventPerspective: false},
            ],
        },
        {
            getCustomDateFunc: this.getCustomDate,
            getScrollSpecialMomentFunc: this.getScrollSpecialMoment,
            getSummaryFunc: this.getSummary,
        });
        
        schedulerData.setResources(data.resources);
        schedulerData.setEvents(data.events);

        this.state = {
            viewModel: schedulerData,
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        const { minMonth, maxMonth } = nextProps.startEndColumns;
        let schedulerData = this.state.viewModel;

        const data = processData(nextProps.data, nextProps.columnKey);

        if(schedulerData.startDate !== minMonth || schedulerData.endDate !== maxMonth){
            schedulerData.startDate = minMonth;
            schedulerData.endDate = maxMonth;
            schedulerData._createHeaders();
            schedulerData._createRenderData();
            schedulerData.setScrollToSpecialMoment(true);
            this.setState({
                viewModel: schedulerData
            })
            return true;
        }

        if(JSON.stringify(data.resources) !== JSON.stringify(schedulerData.resources) || JSON.stringify(data.events) !== JSON.stringify(schedulerData.events)){
            //update resources and events
            schedulerData.setResources(data.resources);
            schedulerData.setEvents(data.events);
            this.setState({
                viewModel: schedulerData
            })
            return true;
        }
                
        return false;
    }
    

    render(){
        const {viewModel} = this.state;
        
        return (
                <Scheduler schedulerData={viewModel}
                    newEvent={this.newEvent}
                    prevClick={this.prevClick}
                    nextClick={this.nextClick}
                    onViewChange={this.onViewChange}
                    onSelectDate={this.onSelectDate}
                    eventItemTemplateResolver={this.eventItemTemplateResolver}
                    eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
                    nonAgendaCellHeaderTemplateResolver = {this.nonAgendaCellHeaderTemplateResolver}
                    slotClickedFunc={this.slotClickedFunc}
                    moveEvent={this.moveEvent}
                    updateEventStart={this.updateEventStart}
                    updateEventEnd={this.updateEventEnd}
                /> 
        )
    }

    prevClick = (schedulerData, data)=> {
        schedulerData.prev();
        schedulerData.setEvents(data.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    nextClick = (schedulerData, data)=> {
        schedulerData.next();
        schedulerData.setEvents(data.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        let newEvent = undefined;

        if(slotId !== 'totalSummaryRow'){
            newEvent = {
                title: slotName,
                start: moment(start).format('YYYY-MM'),
                end: moment(end).format('YYYY-MM'),
                resourceId: slotId,
                quantity: 1.0
            }    
        }
        this.props.creatable.action(newEvent);

    }

    onViewChange = (schedulerData, view, data) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.config.customCellWidth = view.viewType === ViewTypes.Custom ? 30 : 80;
        schedulerData.setEvents(data.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date, data) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(data.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    handleClickEdit = (event) => { 
        this.props.ItemClickEdit.onClick(event);
    }; 

    handleClickDelete = (event) => { 
        this.props.ItemClickDelete.onClick(event);
    }; 

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        if(this.props.movable){
            this.props.movable.action(event, moment(start).format('YYYY-MM'), moment(end).format('YYYY-MM'));
        }
    }

    updateEventStart = (schedulerData, event, newStart) => {
        if(this.props.resizable.start){
            this.props.resizable.startAction(event, moment(newStart).format('YYYY-MM'));
        }
    }

    updateEventEnd = (schedulerData, event, newEnd) => {
        if(this.props.resizable.end){
            this.props.resizable.endAction(event, moment(newEnd).format('YYYY-MM'));

        }
    }

    eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
        let borderWidth = isStart ? '4' : '0';
        let borderColor =  this.props.theme.palette.primary.light, backgroundColor = bgColor;
        let divStyle = {borderLeft: borderWidth + 'px solid ' + borderColor, backgroundColor: backgroundColor, height: mustBeHeight, textAlign: 'right' };
        if(!!agendaMaxEventWidth)
            divStyle = {...divStyle, maxWidth: agendaMaxEventWidth};

        return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}/>
        )
    }

    getSummary = (schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd) => {
        let text, color;

        /* total summary */
        if(slotId === 'totalSummaryRow'){
            let sum = 0;
            let headerMonth = moment(headerStart).format('YYYY-MM');
            let events = schedulerData.events;
            
            events.forEach(event => {
                if(moment(event.start).isSameOrBefore(headerMonth) && moment(event.end).isSameOrAfter(headerMonth)){
                    sum = sum + parseFloat(event.quantity);
                }
            });
            color = this.props.theme.palette.primary.main;
            text = sum > 0 ? Number(sum).toFixed(1) : 0;
        } else {
            if(headerEvents.length === 0){
                color = this.props.theme.palette.text.disabled;
                text = 0;
            } else {
                color = this.props.theme.palette.text.primary;
                text = headerEvents[0].quantity
            }
        }       
        return {text: text, color: color, fontSize: '12px'};
    }

    getScrollSpecialMoment = (schedulerData, startMoment, endMoment) => {
        return moment().subtract(1,'months').format('YYYY-MM-DD')
    }

    getCustomDate = (schedulerData, num, date = undefined) => { 
        const { minMonth, maxMonth } = this.props.startEndColumns;

        let cellUnit = CellUnits.Month;
            
        return {
            startDate : minMonth,
            endDate : maxMonth,
            cellUnit
        };
    }

    eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
        const { ItemClickEdit, ItemClickDelete } = this.props;
        return (
            <div style={{width: '300px'}}>
                <Row type="flex" align="middle">
                    <Col span={20} className="overflow-text">
                        <span className="header2-text" ><b>Allocation details</b></span>
                    </Col>
                    <Col span={2}>
                    { ItemClickEdit && 
                        <Tooltip title={ItemClickEdit.label}>
                            <IconButton size="small" onClick={() => this.handleClickEdit(eventItem)}>
                                <Icon icon={ItemClickEdit.icon} size="inherit"/>
                            </IconButton>
                        </Tooltip>
                    }
                    </Col>
                    <Col span={2}>
                    { ItemClickDelete && 
                        <Tooltip title={ItemClickDelete.label}>
                            <IconButton size="small" onClick={() => this.handleClickDelete(eventItem)}>
                                <Icon icon={ItemClickDelete.icon} size="inherit"/>
                            </IconButton>
                        </Tooltip>
                    }
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col span={24} className="overflow-text">
                        <span className="header2-text" title={title}><b>Name</b>: {title}</span>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col span={24}>
                        <span className="header2-text"><b>Start</b>: {start.format("MM-YYYY")}</span>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col span={24}>
                        <span className="header2-text"><b>End</b>: {end.format("MM-YYYY")}</span>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col span={24}>
                        <span className="header2-text"><b>Quantity</b>: {eventItem.quantity} FTEs</span>
                    </Col>
                </Row>
            </div>
        );
    }

    nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
        let datetime = schedulerData.localeMoment(item.time);
        let isCurrentDate = false;
  
        if (schedulerData.viewType === ViewTypes.Custom2) {
            isCurrentDate = datetime.isSame(new Date(), 'month');
        }
        else if (schedulerData.viewType === ViewTypes.Day) {
            isCurrentDate = datetime.isSame(new Date(), 'hour');
        }
        else {
            isCurrentDate = datetime.isSame(new Date(), 'day');
        }
  
        if (isCurrentDate) {
            style.backgroundColor = this.props.theme.palette.primary.main;
            style.color = this.props.theme.palette.primary.contrastText;
        }else{
            style.color = this.props.theme.palette.text.primary;
        }
  
        return (
            <th key={item.time} className={`header3-text`} style={style}>
                {
                    formattedDateItems.map((formattedItem, index) => (
                        <div key={index}>
                            {this.getLabel(item.time)}
                        </div>
                    ))
                }
            </th>
        );
    }

    getLabel = (date) => {
        return moment(date).format("MMM YYYY");
    }

    slotClickedFunc = (schedulerData, res) => {
        let urlPath = this.props.urlPath;
        this.props.navigate(`${urlPath}/${res.slotId}`); 
    } 
}

export default withDragDropContext(Chronogram)
