import {socket_connect, socket_activity_update, socket_resource_update} from '../actions/actionTypes';

const initialState = {
  socketClient : null,
  activity_updated_at: '',
  resource_updated_at: '',
};

export default function WebsocketReducer (state = initialState, action) {
    switch (action.type) {
      case socket_connect:
        return {
          ...state,
          socketClient : action.socketClient
        };
      case socket_activity_update:
        return {
          ...state,
          activity_updated_at : Date.now()
        };
      case socket_resource_update:
        return {
          ...state,
          resource_updated_at : Date.now()
        };
      default:
        return state;
    }
};