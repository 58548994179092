import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Chronogram from './Chronogram';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

function Index(props) {
    const theme = useTheme();

    const {data, startEndColumns, columnKey, urlPath, ItemClickDelete, ...other } = props;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();  

    useEffect(() => {
        if(data.length > 0)
            setLoading(false);
      }, [data,setLoading])


  return (
    <div id="chronogramArea">
            { loading ? <CircularProgress /> :
            <Chronogram 
              theme={theme}
              data={data} 
              navigate={navigate} 
              columnKey={columnKey}
              urlPath = {urlPath}
              startEndColumns={startEndColumns} 
              ItemClickDelete={ItemClickDelete} 
              {...other}
              /> 
            }
    </div>
  )

  
  
}

export default Index