import React from "react";
import { useSelector } from "react-redux";
import Toolbar from "../../../components/Elements/Toolbar";
import Table from "../../../components/Table/Table";
import { IconButton, Tooltip, Typography } from "@mui/material";
import Icon from "../../../components/Elements/Icon";

const columns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Resource',
    link: true,
    linkPath: 'resources'
  }
];

function ResourcesTable(props) {
  const { onEditButtonClick, onDeleteButtonClick, onAddButtonClick} = props;

  const resources = useSelector((state) => state.resources).resources;
  let rows = resources.map(resource => {
    return {
      id: resource._id,
      name: resource.name
    }
  })

  function onResourceClickEdit(event, value) {
      onEditButtonClick(value);
  }

  function onResourceClickDelete(event, value) {
    onDeleteButtonClick(value);
  }

  return (
    <>
        <Toolbar>
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="toolbarResource" component="div">
                Resources
          </Typography>
          <Tooltip title="Create resource">
              <IconButton onClick={onAddButtonClick} autoFocus>
                  <Icon icon="add"/>
              </IconButton>
          </Tooltip>
        </Toolbar>
        <Table data={rows} columns={columns} columnKey="name" orderBy="name" ItemClickEditIcon={{label: "Edit resource", icon: "edit"}} ItemClickDeleteIcon={{label: "Delete resource", icon: "delete"}} onItemClickEdit={onResourceClickEdit} onItemClickDelete={onResourceClickDelete}/>
    </>
  )
}

export default ResourcesTable;