import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import LinkedLogo from './LinkedLogo'

const BigScreen = (props) => {
    const { links, barTextColor } = props;

    return (
    <>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <LinkedLogo textColor={barTextColor} />
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {links.map((link) => (
                <Button
                component={RouterLink}
                to={link.path}
                key={link.name}
                sx={{ my: 2, display: 'block', color: barTextColor }}
                >
                {link.name}
                </Button>
            ))}
        </Box>
    </>
    );
};

export default BigScreen;