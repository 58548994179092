import {combineReducers} from 'redux';
import resources from './resources';
import activities from './activities';
import snackbar from './snackbar';
import websocket from './websockets';

export default combineReducers({
    resources: resources,
    activities: activities,
    snackbar: snackbar,
    websocket: websocket
})