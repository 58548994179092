import React from 'react'
import TextField from '@mui/material/TextField';
import { Autocomplete as MuiAutocomplete } from '@mui/material';

function Autocomplete(props) {
  const { label, options, onSelect, searchKey, defaultValue, ...other } = props;

  return (
    <MuiAutocomplete
        options={options}
        defaultValue={defaultValue}
        isOptionEqualToValue={(option,value) => option[searchKey] === value[searchKey]}
        getOptionLabel={(option) => option[searchKey]}
        onChange={(event,newInputValue) => {
          onSelect(newInputValue);
        }}
        id="auto-complete"
        autoComplete
        includeInputInList
        renderInput={(params) => (
          <TextField {...params} label={label} {...other}/>
        )}
      />
  )
}
export default Autocomplete