import React, {useEffect} from 'react'
import ResourceDetailsTable from './Table/ResourceActivitiesTable'
import { Grid } from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {clearCurrentResource, getResourceById} from '../../../redux/actions/resources';
import { useParams } from 'react-router-dom';
import { connectToRoom, leaveRoom, receivedSocketMessage } from '../../../redux/actions/websockets';

function ResourceDetails() {
    
    let { id } = useParams();
    const { socketClient, resource_updated_at } = useSelector(state => state.websocket);
    const dispatch = useDispatch();
    
    useEffect(() => {
      return () =>
      {
        dispatch(clearCurrentResource())
        if(socketClient){
          dispatch(leaveRoom(socketClient,id, 'resource'))
        }
      }
    }, [dispatch,socketClient, id])

    useEffect(() => {
      dispatch(getResourceById(id));
    },[dispatch, id, resource_updated_at])


    useEffect(() => {
      if(socketClient){
        dispatch(connectToRoom(socketClient,id,'resource'))
        socketClient.on('message', message => {
          dispatch(receivedSocketMessage(message));
        })
      }    
    }, [socketClient,dispatch,id])

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" >
        <Grid item xs={12}>                     
          <ResourceDetailsTable />
        </Grid>
      </Grid>
    </>
  )
}

export default ResourceDetails