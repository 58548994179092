import axios from 'axios';

const url = '/api/v1/activities';

export const getActivities = () => {
    return axios.get(url)
}

export const getActivityById = (activity_id) => {
    return axios.get(`${url}/${activity_id}?fullInfo=true`)
}

export const createActivity = (activity) => {
    return axios.post(url, activity)
}

export const updateActivity = (activity_id, activity) => {
    return axios.put(`${url}/${activity_id}`, activity)
}

export const deleteActivity = (activity_id) => {
    return axios.delete(`${url}/${activity_id}`)
}

export const allocateResourceToActivity = (activity_id, allocation) => {
    let payload = {
        activity_id :  activity_id,
        resource_id : allocation.resource_id,
        startMonth: allocation.startMonth,
        endMonth: allocation.endMonth,
        quantity: Number(allocation.quantity).toFixed(1)
    };
    return axios.put(`${url}/allocation/add`, payload)
}

export const updateAllocationOfActivity = (activity_id, allocation) => {
    let payload = {
        activity_id,
        allocation_id :  allocation.id,
        resource_id: allocation.resourceId,
        action: allocation.action,
        startMonth: allocation.startMonth,
        endMonth: allocation.endMonth,
        quantity: Number(allocation.quantity).toFixed(1)
    };
    return axios.put(`${url}/allocation/update`, payload)
}


export const removeAllocatedResourceOfActivity = (activity_id, allocation_id) => {
    let payload = {
        activity_id :  activity_id,
        allocation_id : allocation_id,
    };
    return axios.put(`${url}/allocation/remove`, payload)
}

