import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {getActivities, createActivity, deleteActivity} from '../../redux/actions/activities';
import ActivitiesTable from "./Table/ActivitiesTable";
import { Grid, Button, TextField, Typography} from '@mui/material';
import Modal from "../../components/Elements/Modal";
import Form from "../../components/Form/Form";
import DatePicker from "../../components/Elements/DatePicker";
import moment from 'moment';

const activityInitialState = {id: '', name: '', startMonth: moment().format("YYYY-MM"), endMonth: moment().format("YYYY-MM")};

function Activities () {
  const dispatch = useDispatch();
  const { errorSnackbarMessage,successSnackbarMessage,snackbarOpen } = useSelector(state => state.snackbar);

  const [view, setView] = useState('create');
  const [openModal, setOpenModal] = useState(false);
  const [activity, setActivity] = useState(activityInitialState);


  const handleOpenModal = () => setOpenModal(true); 
  const handleCloseModal = () => {
    setOpenModal(false); 
  }

  function onNameChange(e) {
    setActivity({ ...activity, name: e.target.value });
  };

  function onStartMonthChange(date) {
    setActivity({ ...activity, startMonth: date });
  };

  function onEndMonthChange(date) {
    setActivity({ ...activity, endMonth: date });
  };

  function onAddButtonClick(value){
    setActivity(activityInitialState);
    setView('create');
    handleOpenModal();
  }

  function onDeleteButtonClick(value){
    setActivity(value);
    setView('delete');
    handleOpenModal();
  }

  function onFormSubmit() {
    let payload;
      if(view === 'create'){
        payload = { 
          name: activity.name,
          startMonth: activity.startMonth,
          endMonth: activity.endMonth
        };
       dispatch(createActivity(payload));
      }else if(view === 'delete'){
       dispatch(deleteActivity(activity.id));
      }
  };

  useEffect(() => {
    dispatch(getActivities());
  }, [dispatch])

  useEffect(() => {
    if(errorSnackbarMessage === '' && successSnackbarMessage !== ''){
      handleCloseModal()
    }
  }, [errorSnackbarMessage,successSnackbarMessage,snackbarOpen])

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>                     
          <ActivitiesTable onAddButtonClick={onAddButtonClick} onDeleteButtonClick={onDeleteButtonClick} />
        </Grid>
      </Grid>
      <Modal open={openModal} handleClose={handleCloseModal}>
        <Form onSubmitFunc={onFormSubmit}>
        { view === 'delete' ? 
          (
            <>
              <Grid item xs={12}>
                  <Typography variant="h6" component="p">Confirm action</Typography>
                  <Typography variant="body2" component="p">Are you sure you want to delete the activity '{activity.name}' ?</Typography>
                </Grid>
              <Grid item xs={6}>
                <Button variant="outlined" color="error"  size="large" fullWidth onClick={handleCloseModal}>Cancel</Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="error" size="large" type="submit"  fullWidth>Delete</Button>
              </Grid>
            </>
          ) : 
          (
            <>
              <Grid item xs={12}>
                <Typography variant="body2" component="p"> { view === 'create' ? `Create activity`: `Edit activity`}</Typography>
              </Grid>
              <Grid item xs={12}>
                  <TextField name="activityName" variant="outlined" label="Name" placeholder="Enter activity name" fullWidth autoFocus required value={activity.name} onChange={(e) => onNameChange(e)} />
              </Grid>
              <Grid item xs={6}>
                <DatePicker name="activityStartMonth" label="Start month" value={activity.startMonth} required onChange={onStartMonthChange}/>
              </Grid>
              <Grid item xs={6}>
                <DatePicker name="activityEndMonth" label="End month" value={activity.endMonth} required onChange={onEndMonthChange}/>
              </Grid>
              <Grid item xs={6}>
                <Button variant="outlined" color="primary"  size="large" fullWidth onClick={handleCloseModal}>Cancel</Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="primary" size="large" type="submit" fullWidth>Create</Button>
              </Grid>
              </>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default Activities;