import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Logo from './Logo';

const LinkedLogo = (props) => {

    return (
      <RouterLink to="/">
        <Logo {...props} />
      </RouterLink>
    );
};

export default LinkedLogo;