import axios from 'axios';

const url = '/api/v1/resources';

export const getResources = () => {
    return axios.get(url)
}

export const getResourceById = (resource_id) => {
    return axios.get(`${url}/${resource_id}?fullInfo=true`)
}

export const getResourcesNotAllocatedToActivity = (activity_id) => {
    return axios.get(`${url}?NotAllocatedTo=${activity_id}`)
}

export const createResource = (resource) => {
    return axios.post(url, resource)
}

export const updateResource = (resource_id, resource) => {
    return axios.put(`${url}/${resource_id}`, resource)
}

export const deleteResource = (resource_id) => {
    return axios.delete(`${url}/${resource_id}`)
}