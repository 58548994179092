import * as api from '../../api/activities';
import * as api_resources from '../../api/resources';

import {fetch_all_activities, create_activity, update_activity, delete_activity, fetch_activity_by_id, clear_current_activity, socket_resource_update} from '../actions/actionTypes';
import { showSuccessSnackbar, showErrorSnackbar } from './snackbar';
import {socket_activity_update} from '../actions/actionTypes';
import { sendMessageToRoom } from './websockets';

export const getActivities = () => async (dispatch) => {
        const { data } = await api.getActivities();
        dispatch({
            type: fetch_all_activities,
            value: data.activities
        })
}

export const getActivityById = (activity_id) => async (dispatch) => {
    const { data : activity } = await api.getActivityById(activity_id);

    const { data : resources_response } = await api_resources.getResources(activity_id);
    
    dispatch({
        type: fetch_activity_by_id,
        value: {
            activity,
            resources: resources_response.resources
        }
    })
}


export const createActivity = (activity) => async (dispatch) => {

    try {
        const { data } = await api.createActivity(activity);
        dispatch({
            type: create_activity,
            value: data
        })
        dispatch(showSuccessSnackbar('Activity created with success'));
    }catch (error) {
        const { data } = error.response;
        if([2,3].includes(data.code)){
            dispatch(showErrorSnackbar(data.error));
        }else{
            throw error;
        }
    }
}


export const updateActivity = (activity_id, activity, socketClient) => async (dispatch) => {
    try {
        const { data } = await api.updateActivity(activity_id,activity);
        dispatch({
            type: update_activity,
            value: data
        })
        dispatch(sendMessageToRoom(socketClient, activity_id, 'activity' ,socket_activity_update));
        dispatch(showSuccessSnackbar('Activity updated with success'));
    }catch (error) {
        const { data } = error.response;
        if([2,3].includes(data.code)){
            dispatch(showErrorSnackbar(data.error));
        }else{
            throw error;
        }
    }
}

export const allocateResourceToActivity = (activity_id, allocation, socketClient) => async (dispatch) => {
    try {
        await api.allocateResourceToActivity(activity_id, allocation);
        dispatch(getActivityById(activity_id));
        //notify activtiy
        dispatch(sendMessageToRoom(socketClient, activity_id, 'activity' , socket_activity_update));
        //notify resource
        dispatch(sendMessageToRoom(socketClient, allocation.resource_id, 'resource' , socket_resource_update));
        dispatch(showSuccessSnackbar('Resource allocated with success'));
    }catch (error) {
        const { data } = error.response;
        if([2,3,4].includes(data.code)){
            dispatch(showErrorSnackbar(data.error));
        }else{
            throw error;
        }
    }
}

export const updateAllocationOfActivity = (activity_id,allocation, socketClient) => async (dispatch) => {
    try {
        await api.updateAllocationOfActivity(activity_id,allocation);
        dispatch(getActivityById(activity_id));
        dispatch(sendMessageToRoom(socketClient, activity_id, 'activity' , socket_activity_update));
        dispatch(sendMessageToRoom(socketClient, allocation.resourceId, 'resource' , socket_resource_update));
        dispatch(showSuccessSnackbar('Allocation updated with success'));
    }catch (error) {
        const { data } = error.response;
        if([2,3,4].includes(data.code)){
            dispatch(showErrorSnackbar(data.error));
        }else{
            throw error;
        }
    }
}

export const removeAllocatedResourceOfActivity = (activity_id, allocation, socketClient) => async (dispatch) => {
    try {
        await api.removeAllocatedResourceOfActivity(activity_id, allocation.id);
        dispatch(getActivityById(activity_id));
        dispatch(sendMessageToRoom(socketClient, activity_id, 'activity' , socket_activity_update));
        dispatch(sendMessageToRoom(socketClient, allocation.resourceId, 'resource' , socket_resource_update));
        dispatch(showSuccessSnackbar('Resource removed with success'));
    }catch (error) {
        const { data } = error.response;
        if(data.code === 2){
            dispatch(showErrorSnackbar(data.error));
        }else{
            throw error;
        }
    }
}

export const deleteActivity = (activity_id) => async (dispatch) => {
    const { data } = await api.deleteActivity(activity_id);
    dispatch({
        type: delete_activity,
        value: data
    })
    dispatch(showSuccessSnackbar('Activity deleted with success'));
}

export const clearCurrentActivity = () => async (dispatch) => {
    dispatch({type: clear_current_activity})
}