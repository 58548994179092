import React from "react";
import { useSelector } from "react-redux";
import Toolbar from "../../../../components/Elements/Toolbar";
import Index from "../../../../components/Calendar/Index";
import {Typography, Box} from "@mui/material";
import CircularProgress from "../../../../components/Elements/CircularProgress";
import moment from 'moment';


function getMinAndMaxDate(array){
  let startMonths = array.map(a => moment(a.startMonth))
  let endMonths = array.map(a => moment(a.endMonth))

  let minMonth = moment.min(startMonths).format("YYYY-MM");
  let maxMonth = moment.max(endMonths).format("YYYY-MM");

  if(moment(maxMonth).diff(minMonth,'months') < 12){
    maxMonth = moment(minMonth).add(12,'months');
  }
  return { minMonth, maxMonth };
}


function ResourceActivitiesTable() {

  const currentResource = useSelector((state) => state.resources).currentResource;

  let resourceExists = Object.keys(currentResource).length > 0;

  let allocatedActivities = resourceExists ? (currentResource.resource.allocations.map(allocation => {
    return {
      id: allocation._id,
      activity_id: allocation.activity_id,
      name: allocation.activity_name,
      startMonth: moment(allocation.startMonth).format('YYYY-MM'),
      endMonth: moment(allocation.endMonth).format('YYYY-MM'),
      quantity: allocation.quantity
    }
  })) : [];

  return (
    <>
      {resourceExists ?
      (
        <>
          <Toolbar>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="toolbarResource" component="div">
                {`${currentResource.resource.name}'s activities`}
            </Typography>
          </Toolbar>
          { allocatedActivities.length > 0 ?
           <Index data={allocatedActivities} startEndColumns={getMinAndMaxDate(allocatedActivities)} columnKey='activity_id' urlPath='/activities'/> :
          <Typography sx={{ flex: '1 1 100%', padding: '20px 0px 20px 0px' }} variant="body2"> No activities found </Typography> }
        </>
      ) : 
       (
         <>
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <CircularProgress />
          </Box>
        </>
       )
      }
    </>
  )
}

export default ResourceActivitiesTable;