export const processData = (rawData,columnKey) => {

    let data = {
        resources: [],
        events: []
    }

    let rows = rawData.filter((value, index, self) => {
        return self.findIndex(v => v[columnKey] === value[columnKey]) === index;
      }).map(elem => {
        return { id: elem[columnKey], name: elem.name};
      }).sort( comparator );


    //columns 
    rows.forEach(row => {
        data.resources.push({
            id: row.id,
            name: row.name
        })
    });

    //add total row
    let total = {
        id: 'totalSummaryRow',
        name: 'Total'
    }
    data.resources.push(total);

    //events
    rawData.forEach(elem => {
        data.events.push({
                id: elem.id,
                //maintain lib dates format
                start: elem.startMonth,
                end: `${elem.endMonth}-01 23:59:59`,
                resourceId: elem[columnKey],
                title: elem.name,
                bgColor: "#D9D9D9",
                showPopover: true,
                quantity: Number(elem.quantity).toFixed(1) || 0
        });
    });

    return data;
}

function comparator(a, b) { 
    if (b.name.toLowerCase() < a.name.toLowerCase()) { 
        return 1; 
    } 
    if (b.name.toLowerCase() > a.name.toLowerCase()) { 
        return -1; 
    }  
    return 0; 
} 