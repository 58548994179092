import {fetch_all_resources, fetch_resource_by_id, create_resource, update_resource, delete_resource,clear_current_resource} from '../actions/actionTypes';

const initialState = {
    resources : [],
    currentResource: {}
};

export default function ResourcesReducer (state = initialState, action) {
    let index;

    switch(action.type) {
        case fetch_all_resources:
            return {
                ...state,
                resources: action.value,
                currentResource: {}
            };
        case fetch_resource_by_id:
            return {
                ...state,
                currentResource: action.value
            };
        case create_resource:
            return {
                ...state,
                resources: [...state.resources, action.value]
            };
        case update_resource:
            index = state.resources.findIndex(elem => elem._id === action.value._id)
            state.resources[index] = action.value; 
            return {
                ...state,
            };
        case delete_resource:  
            if( action.value.deletedCount === 1) {
                index = state.resources.findIndex(elem => elem._id === action.value.deleted_id)
                state.resources.splice(index,1); 
            }
            return {
                ...state,
            };
        case clear_current_resource:  
            return {
                ...state,
                currentResource: {}
            };
        default: 
            return state;
    }
}