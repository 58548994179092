import * as api from '../../api/resources';
import {fetch_all_resources,fetch_resource_by_id, create_resource, update_resource, delete_resource, clear_current_resource} from '../actions/actionTypes';
import { showSuccessSnackbar, showErrorSnackbar } from './snackbar';

export const getResources = () => async (dispatch) => {
        const { data } = await api.getResources();
        dispatch({
            type: fetch_all_resources,
            value: data.resources
        })
}

export const getResourceById = (resource_id) => async (dispatch) => {
    const { data : resource } = await api.getResourceById(resource_id);

    dispatch({
        type: fetch_resource_by_id,
        value: {
            resource
        }
    })
}

export const createResource = (resource) => async (dispatch) => {

    try {
        const { data } = await api.createResource(resource);
        dispatch({
            type: create_resource,
            value: data
        })
        dispatch(showSuccessSnackbar('Resource created with success'));
    }catch (error) {
        const { data } = error.response;
        if(data.code === 2){
            dispatch(showErrorSnackbar(data.error));
        }else{
            throw error;
        }
    }
}


export const updateResource = (resource_id, resource) => async (dispatch) => {
    try {
        const { data } = await api.updateResource(resource_id,resource);
        dispatch({
            type: update_resource,
            value: data
        })
        dispatch(showSuccessSnackbar('Resource updated with success'));
    }catch (error) {
        const { data } = error.response;
        if(data.code === 2){
            dispatch(showErrorSnackbar(data.error));
        }else{
            throw error;
        }
    }
}

export const deleteResource = (resource_id) => async (dispatch) => {
    const { data } = await api.deleteResource(resource_id);
    dispatch({
        type: delete_resource,
        value: data
    })
    dispatch(showSuccessSnackbar('Resource deleted with success'));
}

export const clearCurrentResource = () => async (dispatch) => {
    dispatch({type: clear_current_resource})
}