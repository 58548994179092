import {snackbar_clear, snackbar_error, snackbar_success, snackbar_warning} from '../actions/actionTypes';

export const showSuccessSnackbar = message => {
    return dispatch => {
      dispatch({ type: snackbar_success, message });
    };
  };

export const showErrorSnackbar = message => {
  return dispatch => {
    dispatch({ type: snackbar_error, message });
  };
};

export const showWarningSnackbar = message => {
  return dispatch => {
    dispatch({ type: snackbar_warning, message });
  };
};
  
  export const clearSnackbar = () => {
    return dispatch => {
      dispatch({ type: snackbar_clear });
    };
  };