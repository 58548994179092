import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';

function DatePicker(props) {
    const { value, onChange, label,required, ...other } = props;

    const handleChange = (date) => {
        onChange(date !== null ? date.format("YYYY-MM") : '')
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
                {...other}
                label={label}
                inputFormat="YYYY-MM"
                views={['year','month']}
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField required={required} {...params} />}
                />
        </LocalizationProvider>
    )
}

export default DatePicker