import React, {useEffect} from 'react'
import ResourceDetailsTable from './Table/ResourceActivitiesTable'
import { Grid } from '@mui/material';
import {useDispatch} from 'react-redux';
import {clearCurrentResource, getResourceById} from '../../../redux/actions/resources';
import { useParams } from 'react-router-dom';

function ResourceDetails() {
    
    let { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getResourceById(id));
      return () =>
      {
        dispatch(clearCurrentResource())
      }
    }, [dispatch, id])

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" >
        <Grid item xs={12}>                     
          <ResourceDetailsTable />
        </Grid>
      </Grid>
    </>
  )
}

export default ResourceDetails