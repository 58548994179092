import React, {useState} from 'react'; 
import {IconButton, TableHead, Tooltip, Link, Paper} from "@mui/material";
import Box from '@mui/material/Box'; 
import {Table as MuiTable, TableBody, TableCell, TableContainer, TableRow} from '@mui/material'; 
import Icon from '../Elements/Icon';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

function getComparator(orderByName, orderByDate) { 
  return (a, b) => {
    if(orderByName){
      //name
      if (b[orderByName].toLowerCase() < a[orderByName].toLowerCase()) { 
        return 1; 
      } 
      if (b[orderByName].toLowerCase() > a[orderByName].toLowerCase()) { 
        return -1; 
      }  
    }
    
    if(orderByDate){
      //name
      if (moment(b[orderByDate]).isBefore(a[orderByDate])) { 
        return 1; 
      } 
      if (moment(b[orderByDate]).isAfter(a[orderByDate])) { 
        return -1; 
      } 
    }
    return 0; 
  }
} 
 
// This method is created for cross-browser compatibility, if you don't 
// need to support IE11, you can use Array.prototype.sort() directly 
function stableSort(array, comparator) { 
  const stabilizedThis = array.map((el, index) => [el, index]); 
  stabilizedThis.sort((a, b) => { 
    const order = comparator(a[0], b[0]); 
    if (order !== 0) { 
      return order; 
    } 
    return a[1] - b[1]; 
  }); 
  return stabilizedThis.map((el) => el[0]); 
} 

const isLink = (columns,columnId) => {
  let a = columns.find(elem => elem.id === columnId);
  return a.link;
}

const getLinkPath = (columns,columnId) => {
  let a = columns.find(elem => elem.id === columnId);
  return a.linkPath;
}
 
export default function Table(props) { 
  const {data, columns, columnKey, orderByName, orderByDate, groupBy, showHeader, status, linkKey, onItemClickEdit, ItemClickEditIcon, onItemClickDelete, ItemClickDeleteIcon } = props; 
  const [selected, setSelected] = useState(''); 
  let lastValue = '';

  const handleClickEdit = (event, item) => { 
    setSelected(item[columnKey]);
    onItemClickEdit(event,item);
  }; 

  const handleClickDelete = (event, item) => { 
    setSelected(item[columnKey]);
    onItemClickDelete(event,item);
  }; 

 
  const isSelected = (key) => {
    return selected === key; 
    }

    return ( 
    <Paper>
      <Box sx={{ width: '100%' }}>
        <TableContainer> 
          <MuiTable 
            sx={{ minWidth: 750 }} 
            aria-labelledby="tableTitle" 
            size={'medium'} 
          > 
            {showHeader && (
              <TableHead>
                <TableRow 
                  key="columns">
                {status !== undefined &&  <TableCell/> }
                {columns.map(column => {
                          const cellId = `HeaderCellId-${column.id}`; 

                          return (
                            <TableCell 
                              component="th" 
                              id={`column_${column.id}`} 
                              scope="row" 
                              padding="normal" 
                              key={cellId}
                            > 
                              {column.label}
                            </TableCell>               
                        )})}
                  <TableCell/>
                </TableRow>
              </TableHead>
            )}
            <TableBody> 
              {/* if you don't need to support IE11, you can replace the `stableSort` call with: 
                 rows.slice().sort(getComparator(order, orderBy)) */} 
              {stableSort(data, getComparator(orderByName, orderByDate)) 
                .map((row, index) => { 
                  const isItemSelected = isSelected(row[columnKey]); 
                  const labelId = `enhanced-table-checkbox-${index}`; 
                  let currentValue = row[groupBy];
                  return ( 
                    <TableRow 
                      role="checkbox" 
                      aria-checked={isItemSelected} 
                      tabIndex={-1} 
                      key={row[columnKey]} 
                      selected={isItemSelected} 
                    > 
                      
                     {status !== undefined && 
                        <TableCell padding="checkbox">
                          { row[status.key] === "warning" && 
                            <Tooltip title={status.message}>
                              <div style={{textAlign: "center"}}>
                                <Icon icon="important" size="small"/> 
                              </div>
                            </Tooltip>
                          }
                        </TableCell>         
                      }
                      {columns.map(column => {
                            const cellId = `cellId-${column.id}`;
                            currentValue = row[column.id];

                            /* START GroupBy block */
                            if(groupBy){
                              if(column.id === groupBy && lastValue === currentValue ){
                                currentValue = undefined;
                              }
                              lastValue = row[groupBy]
                            }
                            /* END GroupBy block */

                            return (
                              <TableCell 
                                component="th" 
                                id={labelId} 
                                scope="row" 
                                padding="normal" 
                                key={cellId}
                              > 
                                {isLink(columns, column.id) ?            
                                  <Link component={RouterLink} to={`/${getLinkPath(columns, column.id)}/${row[linkKey] || row.id}`}>
                                    {currentValue}
                                  </Link>
                                :
                                  currentValue
                                } 
                              </TableCell>               
                      )})}

                    <TableCell padding="checkbox">
                      <div style={{display: "flex", flexDirection: "row"}}>
                      { onItemClickEdit !== undefined && 
                          <Tooltip title={ItemClickEditIcon.label}>
                            <IconButton onClick={(event)=> handleClickEdit(event, row)}>
                                <Icon icon={ItemClickEditIcon.icon} size="small"/>
                            </IconButton>
                          </Tooltip>
                      }
                      { onItemClickDelete !== undefined && 
                        <Tooltip title={ItemClickDeleteIcon.label}>
                          <IconButton onClick={(event)=> handleClickDelete(event, row)}>
                              <Icon icon={ItemClickDeleteIcon.icon} size="small"/>
                          </IconButton>
                        </Tooltip>
                      }
                      </div>
                       </TableCell>
                    </TableRow> 
                  ); 
                })
                // lastValue = row[column.id];
                }
            </TableBody> 
          </MuiTable> 
        </TableContainer> 
    </Box> 
  </Paper>
  ); 
} 