import './App.css';
import React from 'react';
import Resources from './pages/Resources/Resources';
import Activities from './pages/Activities/Activities';
import { Routes, Route, Navigate } from 'react-router-dom';
import ActivityDetails from './pages/Activities/Activity/ActivityDetails';
import ResourceDetails from './pages/Resources/Resource/ResourceDetails';
import Snackbar from './components/Elements/Snackbar';
import ResponsiveAppBar from './components/AppBar/Responsive';
import Container from '@mui/material/Container';


function App() {

  const appBarLinks = [
    {
      path: '/resources',
      name: 'Resources'
    },
    {
      path: '/activities',
      name: 'Activities'
    }
  ];

  return (
    <>
      <ResponsiveAppBar links={appBarLinks} />
      <Container maxWidth="xl">
        <main className="App">
          <Routes>
            <Route path="/" element={ <Navigate to="/activities"/> } />
            <Route path="/resources" element={<Resources />} />
            <Route path="/activities" element={<Activities />} /> 
            <Route path="/activities/:id" element={<ActivityDetails />} /> 
            <Route path="/resources/:id" element={<ResourceDetails />} /> 
          </Routes>
        </main>
        <Snackbar />
      </Container>
    </>
  );
}

export default App;
