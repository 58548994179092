import * as React from 'react';
import { CircularProgress as MuiCircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

export default function CircularProgress() {
  return (
    <Box sx={{ display: 'flex' }}>
      <MuiCircularProgress />
    </Box>
  );
}