import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import BigScreen from './BigScreen';
import SmallScreen from './SmallScreen';
import { useTheme } from '@mui/material/styles';

const Responsive = (props) => {

  const { links } = props;

  const theme = useTheme();
  const primaryContrast = theme.palette.primary.contrastText;

  return (
    <AppBar position="static" background="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <BigScreen links={links} barTextColor={primaryContrast} />

          <SmallScreen links={links} barTextColor={primaryContrast} />

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Responsive;
