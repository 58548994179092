import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import WarningIcon from '@mui/icons-material/Warning';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import MenuIcon from '@mui/icons-material/Menu';

function Icon(props) {
    const {icon, size, iconStyle} = props;
    const getIcon = (iconType) => {
        switch(iconType){
            case "add":
                return <AddIcon fontSize={size} style={{...iconStyle}} />;
            case "delete":
                return <DeleteIcon fontSize={size} style={{...iconStyle}} />;
            case "clear":
                return <ClearIcon fontSize={size} style={{...iconStyle}} />;
            case "remove":
                return <RemoveIcon fontSize={size} style={{...iconStyle}} />;
            case "edit":
                return <EditIcon fontSize={size} style={{...iconStyle}} />;
            case "addLink":
                return <AddLinkIcon fontSize={size} style={{...iconStyle}} />;
            case "removeLink":
                return <LinkOffIcon fontSize={size} style={{...iconStyle}} />;
            case "warning":
                return <WarningIcon fontSize={size} style={{...iconStyle}} />;
            case "important":
                return <PriorityHighIcon fontSize={size} style={{...iconStyle}} />;
            case "menu":
                return <MenuIcon fontSize={size} style={{...iconStyle}} />;
            default:
                return null;
        }
    };
  return (
    getIcon(icon)
  )
}

export default Icon