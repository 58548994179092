import {socket_connect} from './actionTypes';
import io from 'socket.io-client';


export const connectToSocket = () => {
    var socketClient = io({
            path: '/api/websockets'
          });
    
    socketClient.emit('updateMessage', 'hello all')
    return dispatch => {
        dispatch({ type: socket_connect, socketClient });
    };
  };

export const connectToRoom = (socketClient, id, type) => { 
    let room = `${type}_${id}`
    socketClient.emit('joinRoom', room)
    return dispatch => {
        dispatch({ type });
    };
};

export const leaveRoom = (socketClient, id, type) => {
    let room = `${type}_${id}`
    socketClient.off('message');
    socketClient.emit('leaveRoom', room)
    return dispatch => {
        dispatch({ type });
    };
};

export const sendMessageToRoom = (socketClient, id, type, message) => {
    let room = `${type}_${id}`
    socketClient.emit('messageToRoom', { room, message })
    return dispatch => {
        dispatch({ type });
    };
};
export const receivedSocketMessage = (data) => {
    const { type, message } = data; 
    if(type === 'message'){
        return dispatch => {
            dispatch({ type: message });
        };
    }else{
        //other msgs
        return dispatch => {
            dispatch({ type });
        };
    }
};