import {fetch_all_activities, create_activity, update_activity, delete_activity, fetch_activity_by_id, clear_current_activity} from '../actions/actionTypes';

const initialState = {
    activities : [],
    currentActivity: {}
};

export default function ActivitiesReducer (state = initialState, action) {
    let index;

    switch(action.type) {
        case fetch_all_activities:
            return {
                ...state,
                activities: action.value,
                currentActivity : {}
            };
        case fetch_activity_by_id:
            return {
                ...state,
                currentActivity: action.value
            };
        case create_activity:
            return {
                ...state,
                activities: [...state.activities, action.value]
            };
        case update_activity:
            index = state.activities.findIndex(elem => elem._id === action.value._id)
            state.activities[index] = action.value; 
            return {
                ...state,
                currentActivity : {
                    ...state.currentActivity,
                    activity: action.value
                }
            };
        case delete_activity:  
            if( action.value.deletedCount === 1) {
                index = state.activities.findIndex(elem => elem._id === action.value.deleted_id)
                state.activities.splice(index,1); 
            }
            return {
                ...state,
            };
        case clear_current_activity:  
            return {
                ...state,
                currentActivity: {}
            };
        default: 
            return state;
    }
}