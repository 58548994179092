import React from "react";
import { useSelector } from "react-redux";
import Toolbar from "../../../components/Elements/Toolbar";
import Table from "../../../components/Table/Table";
import { IconButton, Tooltip, Typography } from "@mui/material";
import Icon from "../../../components/Elements/Icon";
import moment from "moment";

const columns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    link: true,
    linkPath: 'activities'
  },
  {
    id: 'startMonth',
    numeric: false,
    disablePadding: true,
    label: 'Start month',
    link: false
  },
  {
    id: 'endMonth',
    numeric: false,
    disablePadding: true,
    label: 'End month',
    link: false
  },
];

function ActivitiesTable(props) {
  const { onDeleteButtonClick, onAddButtonClick} = props;

  const activities = useSelector((state) => state.activities).activities;
  let rows = activities.map(activity => {
    return {
      id: activity._id,
      name: activity.name,
      startMonth: moment(activity.startMonth).format('YYYY-MM'),
      endMonth: moment(activity.endMonth).format('YYYY-MM')
    }
  })

  function onActivityClickDelete(event, value) {
    onDeleteButtonClick(value);
  }

  return (
    <>
        <Toolbar>
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="toolbarActivity" component="div">
                Activities
          </Typography>
          <Tooltip title="Create activity">
              <IconButton onClick={onAddButtonClick} autoFocus>
                  <Icon icon="add"/>
              </IconButton>
          </Tooltip>
        </Toolbar>
        <Table data={rows} columns={columns} showHeader columnKey="name" orderBy="name"  ItemClickDeleteIcon={{label: "Delete activity", icon: "delete"}} onItemClickDelete={onActivityClickDelete}/>
    </>
  )
}

export default ActivitiesTable;