import React, {useState, useEffect} from 'react'
import ActivityResourcesTable from './Table/ActivityResourcesTable'
import { Grid, Typography, Button, TextField } from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import { showWarningSnackbar } from '../../../redux/actions/snackbar';
import {getActivityById, updateActivity, allocateResourceToActivity, removeAllocatedResourceOfActivity, clearCurrentActivity, updateAllocationOfActivity} from '../../../redux/actions/activities';
import Form from '../../../components/Form/Form';
import Modal from '../../../components/Elements/Modal';
import Autocomplete from '../../../components/Elements/Autocomplete';
import { useParams } from 'react-router-dom';
import DatePicker from '../../../components/Elements/DatePicker';
import moment from 'moment';

function ActivityDetails() {
    
    let { id } = useParams();
    const dispatch = useDispatch();
    const currentActivity = useSelector((state) => state.activities).currentActivity;
    const { errorSnackbarMessage, successSnackbarMessage, snackbarOpen } = useSelector(state => state.snackbar);

    const [activity, setActivity] = useState({name: '', startMonth: '', endMonth: ''});
    const [resourcesOptions, setResourcesOptions] = useState([]);
    const [allocation, setAllocation] = useState({});
    const [view, setView] = useState('create');
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => setOpenModal(true); 
    const handleCloseModal = () => {
      setOpenModal(false); 
    }

    const onNameChange = (e) => setActivity({ ...activity, name: e.target.value});

    function onStartMonthChange(date) {
      setActivity({ ...activity, startMonth: date });
    };
  
    function onEndMonthChange(date) {
      setActivity({ ...activity, endMonth: date });
    };

    function onAllocationStartMonthChange(date) {
      setAllocation({ ...allocation, startMonth: date });
    };
  
    function onAllocationEndMonthChange(date) {
      setAllocation({ ...allocation, endMonth: date });
    };

    function onAllocationQuantityChange(e) {
      let quantity = e.target.value;
      if(quantity !== allocation.quantity){
        setAllocation({ ...allocation, quantity: quantity });
      }
    };

    function onSelectedResource(resource_data){
      setAllocation(resource_data === null ? 
        {
          startMonth: allocation.startMonth,
          endMonth: allocation.endMonth
        } : 
        {
          ...allocation,
          resource_id: resource_data._id,
          name: resource_data.name, 
        });
    }

    function onAddButtonClick(value){
        setView('allocate');
        setActivity( { name: currentActivity.activity.name, startMonth: currentActivity.activity.startMonth, endMonth: currentActivity.activity.endMonth});
        setAllocation({startMonth: currentActivity.activity.startMonth, endMonth: currentActivity.activity.endMonth, quantity: 1})
        setResourcesOptions(currentActivity.resources);
        handleOpenModal();
    }

    function onEditButtonClick(value){
      setView('edit');
      setActivity( { name: currentActivity.activity.name, startMonth: currentActivity.activity.startMonth, endMonth: currentActivity.activity.endMonth});
      handleOpenModal();
    }

    function onEditAllocationButtonClick(value){
      setView('editAllocation');
      setActivity( { name: currentActivity.activity.name, startMonth: currentActivity.activity.startMonth, endMonth: currentActivity.activity.endMonth});
      setResourcesOptions(currentActivity.resources);
      setAllocation({...value, action: 'update'});
      handleOpenModal();
  }
    
    
    function onRemoveButtonClick(value){
      setAllocation(value)
      setView('remove');
      handleOpenModal();
    }

    function onUpdateAllocation(value,newStart,newEnd){
      if(newStart === value.startMonth) {
        dispatch(showWarningSnackbar("Ignoring move to same months"));
      }
      else{
        setView('moveAllocation');
        let allocation = {
          ...value,
          action: 'move',
          startMonth: newStart,
          endMonth: newEnd
        }
        setAllocation(allocation)
        handleOpenModal();
      }
    }

    function onCreateAllocation(value){
        if(value === undefined){
          return onAddButtonClick();
        }
        
        let allocation = {
          ...value,
        }

        setView('createAllocation');
        setAllocation(allocation)
        handleOpenModal();
    }

    function onResize(value,newDate,action){
      let sameDate = false;
      let allocation = {
        ...value,
        action: action
      }
      switch(action){
        case 'start':
          if(newDate === moment(value.startMonth).format('YYYY-MM')) sameDate = true;
          allocation.startMonth = newDate;
          break;
        case 'end':
          if(newDate === moment(value.endMonth).format('YYYY-MM')) sameDate = true;
          allocation.endMonth = newDate;
          break;
        default:
          break;
      }
     
      if(sameDate) {
        dispatch(showWarningSnackbar("Ignoring resize to same size"));
      }
      else{
        setView('moveAllocation');
        setAllocation(allocation)
        handleOpenModal();
      }
    }

    function onFormSubmit() {
      let payload;
        if(view === 'edit'){
          payload = { 
            name: activity.name,
            startMonth: activity.startMonth,
            endMonth: activity.endMonth
          };
          dispatch(updateActivity(id, payload));
        }else if(view === 'allocate' || view ==='createAllocation'){
          dispatch(allocateResourceToActivity(id,allocation));
        }else if(view === 'moveAllocation' || view === 'editAllocation'){
           dispatch(updateAllocationOfActivity(id,allocation));
        }else if(view === 'remove'){
          dispatch(removeAllocatedResourceOfActivity(id,allocation.id));
        }
    };


    useEffect(() => {
      dispatch(getActivityById(id));
      return () =>
      {
        dispatch(clearCurrentActivity())
      }
    }, [dispatch, id])

    useEffect(() => {
      if(errorSnackbarMessage === '' && successSnackbarMessage !== ''){
        handleCloseModal()
      }
    }, [errorSnackbarMessage,successSnackbarMessage,snackbarOpen])

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" >
        <Grid item xs={12}>                     
          <ActivityResourcesTable 
            onAddButtonClick={onAddButtonClick} 
            onEditButtonClick={onEditButtonClick} 
            onDeleteButtonClick={onRemoveButtonClick} 
            onAllocationEditButtonClick={onEditAllocationButtonClick}
            onMoveEvent={onUpdateAllocation} 
            onCreateEvent={onCreateAllocation}
            onResize={onResize} 
          />
        </Grid>
      </Grid>
      <Modal open={openModal} handleClose={handleCloseModal}>
        <Form onSubmitFunc={onFormSubmit}>
            <Grid item xs={12}>
                <Typography variant="body2" component="p">
                  { view === 'allocate' && `Allocate resource`} 
                  { view === 'createAllocation' && `Create new allocation`} 
                  { view === 'editAllocation' && `Edit allocation`}
                  { view === 'edit' && `Edit activity`} 
                  </Typography>
            </Grid>
            { view === 'allocate' && (
              <>
                <Grid item xs={12}>
                    <Autocomplete autoFocus label="Resource" searchKey="name" options={resourcesOptions} onSelect={onSelectedResource}/>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker name="allocationStartMonth" label="Start month" value={allocation.startMonth} minDate={moment(activity.startMonth)} maxDate={moment(activity.endMonth)} required onChange={onAllocationStartMonthChange}/>
                </Grid>
                <Grid item xs={6}>
                <DatePicker name="allocationEndMonth" label="End month" value={allocation.endMonth} minDate={moment(activity.startMonth)} maxDate={moment(activity.endMonth)} required onChange={onAllocationEndMonthChange}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField name="allocationQuantity" variant="outlined" type="number" label="Quantity (FTEs)" placeholder="Enter quantity (FTEs)" value={allocation.quantity}       
                    fullWidth autoFocus required onChange={e => onAllocationQuantityChange(e)} InputProps={{inputProps: { min: 0.1, step: 0.1 }}}/>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" disabled={!("name" in allocation)} color="primary" size="large" type="submit" fullWidth>Allocate</Button>
                </Grid>
              </>
            )}
            { view === 'edit' && (
              <>
                <Grid item xs={12}>
                    <TextField name="activityName" variant="outlined" label="Name" placeholder="Enter activity name" fullWidth autoFocus required value={activity.name} onChange={(e) => onNameChange(e)} />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker name="activityStartMonth" label="Start month" value={activity.startMonth} required onChange={onStartMonthChange}/>
                </Grid>
                <Grid item xs={6}>
                <DatePicker name="activityEndMonth" label="End month" value={activity.endMonth} required onChange={onEndMonthChange}/>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="outlined" color="primary"  size="large" fullWidth onClick={handleCloseModal}>Cancel</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" size="large" type="submit" fullWidth>Update</Button>
                </Grid>
              </>
            )}
            { (view === 'editAllocation' || view === 'createAllocation') && (
              <>
              <Grid item xs={12}>
                <Autocomplete 
                    autoFocus 
                    label="Resource" 
                    searchKey="name" 
                    options={resourcesOptions} 
                    onSelect={onSelectedResource} 
                    defaultValue={{_id: allocation.resourceId, name: allocation.name}}
                    />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker name="allocationStartMonth" label="Start month" value={allocation.startMonth} minDate={moment(activity.startMonth)} maxDate={moment(activity.endMonth)} required onChange={onAllocationStartMonthChange}/>
                </Grid>
                <Grid item xs={6}>
                <DatePicker name="allocationEndMonth" label="End month" value={allocation.endMonth} minDate={moment(activity.startMonth)} maxDate={moment(activity.endMonth)} required onChange={onAllocationEndMonthChange}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField name="allocationQuantity" variant="outlined" type="number" label="Quantity (FTEs)" placeholder="Enter quantity (FTEs)" value={allocation.quantity}       
                    fullWidth autoFocus required onChange={e => onAllocationQuantityChange(e)} InputProps={{inputProps: { min: 0.1, step: 0.1 }}}/>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" size="large" type="submit" fullWidth>{ view === 'editAllocation' ? 'Save changes' : 'Create allocation'}</Button>
                </Grid>
              </>
            )}
            { view === 'moveAllocation' &&
            (
              <>
                <Grid item xs={12}>
                    <Typography variant="h6" component="p">Confirm action</Typography>
                    <Typography variant="body2" component="p">
                      Move allocation {allocation.action === 'end' ? 'end' : 'start'} month to <b>{moment(allocation.action === 'end' ? allocation.endMonth : allocation.startMonth).format("MMM YYYY")}</b>?
                    </Typography>
                  </Grid>
              <Grid item xs={6}>
                <Button variant="outlined" color="primary"  size="large" fullWidth onClick={handleCloseModal}>Cancel</Button>
              </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" size="large" type="submit"  fullWidth>Move</Button>
                </Grid>
              </>
            )}
            { view === 'remove' &&
            (
              <>
                <Grid item xs={12}>
                    <Typography variant="h6" component="p">Confirm action</Typography>
                    <Typography variant="body2" component="p">Remove <b>{allocation.name}</b> allocation?</Typography>
                  </Grid>
              <Grid item xs={6}>
                <Button variant="outlined" color="error"  size="large" fullWidth onClick={handleCloseModal}>Cancel</Button>
              </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="error" size="large" type="submit"  fullWidth>Remove</Button>
                </Grid>
              </>
            )}
        </Form>
      </Modal>  
    </>
  )
}

export default ActivityDetails