import React from 'react'
import { Snackbar as MuiSnackbar, Alert } from '@mui/material';
import { useDispatch, useSelector} from 'react-redux';
import { clearSnackbar } from '../../redux/actions/snackbar';

function Snackbar(props) {
    const {autoHideDuration} = props;

    const dispatch = useDispatch();
    const { successSnackbarMessage, errorSnackbarMessage, warningSnackbarMessage, snackbarOpen } = useSelector(state => state.snackbar);

    function handleClose() {
      dispatch(clearSnackbar());
    }
  return (
    <>
    { (successSnackbarMessage !== '' || errorSnackbarMessage  !== '' || warningSnackbarMessage !== '') &&
     (<MuiSnackbar open={snackbarOpen} autoHideDuration={autoHideDuration || 2000} onClose={handleClose}>
          <Alert severity={successSnackbarMessage ? 'success' :  errorSnackbarMessage ? 'error' : 'warning'} sx={{ width: '100%' }}>
            {successSnackbarMessage || errorSnackbarMessage || warningSnackbarMessage}
          </Alert>
      </MuiSnackbar>)
    }
    </>
  )
}

export default Snackbar