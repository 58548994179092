import React from "react";
import { useSelector } from "react-redux";
import Toolbar from "../../../../components/Elements/Toolbar";
import Index from "../../../../components/Calendar/Index";
import { IconButton, Tooltip, Typography, Box} from "@mui/material";
import Icon from "../../../../components/Elements/Icon";
import CircularProgress from "../../../../components/Elements/CircularProgress";
import moment from 'moment';

function getMinAndMaxDate(activity){
  return { minMonth: activity.startMonth, maxMonth: activity.endMonth };
}

function ActivityResourcesTable(props) {
  const { onAddButtonClick, onEditButtonClick, onAllocationEditButtonClick,  onDeleteButtonClick, onMoveEvent, onResize, onCreateEvent } = props;
  const currentActivity = useSelector((state) => state.activities).currentActivity;

  let activityExists = Object.keys(currentActivity).length > 0;

  let allocatedResources = activityExists ? (currentActivity.activity.allocations.map(allocation => {
    return {
      id: allocation._id,
      name: allocation.resource.name,
      resource_id : allocation.resource._id,
      startMonth: moment(allocation.startMonth).format('YYYY-MM'),
      endMonth: moment(allocation.endMonth).format('YYYY-MM'),
      quantity: allocation.quantity || 0
    }
  })) : [];


  function onAllocationClickRemove(value) {
    //rename  object key 'title' (mandatory to chronogram) to 'name'
    value.name = value.title;
    delete value.title;
    onDeleteButtonClick(value);
  }

  function onAllocationClickEdit(value) {
    let allocation = {
      id: value.id,
      resourceId: value.resourceId,
      name: value.title,
      startMonth: value.start,
      endMonth: value.end,
      quantity: value.quantity
    };
    onAllocationEditButtonClick(allocation);
  }

  function onMovedEvent(value,newStart,newEnd) {
    value.startMonth = value.start;
    value.endMonth = value.end;

    delete value.start;
    delete value.end;
    onMoveEvent(value,newStart,newEnd);
  }

  function onCreatedEvent(value) {
    if(value === undefined){
      return onCreateEvent(value);
    }
    let allocation = {
      resource_id: value.resourceId,
      name: value.title,
      startMonth: value.start,
      endMonth: value.end,
      quantity: 1.0
    };
    onCreateEvent(allocation);
  }
  
  function onResizeStartAction(value,newStart) {
    value.startMonth = value.start;
    value.endMonth = value.end;

    delete value.start;
    delete value.end;
    onResize(value,newStart,'start');
  }

  function onResizeEndAction(value,newEnd) {
    value.startMonth = value.start;
    value.endMonth = value.end;

    delete value.start;
    delete value.end;
    onResize(value,newEnd,'end');
  }

  return (
    <>
      {activityExists ?
      (
        <>
          <Toolbar>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="toolbarActivity" component="div">
                {`${currentActivity.activity.name}'s allocated resources`}
            </Typography>
            <Tooltip title="Edit activity">
                <IconButton onClick={onEditButtonClick}>
                  <Icon icon="edit"/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Allocate resource">
                <IconButton onClick={onAddButtonClick} autoFocus>
                  <Icon icon="addLink"/>
                </IconButton>
            </Tooltip>
          </Toolbar>
          { allocatedResources.length > 0 ? 
          <Index 
            data={allocatedResources} 
            startEndColumns={getMinAndMaxDate(currentActivity.activity)}  
            columnKey='resource_id' 
            urlPath='/resources' 
            ItemClickEdit={{label: "Edit allocation", icon: "edit", onClick: onAllocationClickEdit}}
            ItemClickDelete={{label: "Remove allocation", icon: "removeLink", onClick: onAllocationClickRemove}} 
            movable={{ action : onMovedEvent }} 
            creatable={{ action : onCreatedEvent }} 
            resizable={{ start : true, startAction: onResizeStartAction, end: true, endAction: onResizeEndAction}}
          /> :
          <Typography sx={{ flex: '1 1 100%', padding: '20px 0px 20px 0px' }} variant="body2"> 0 resources allocated to this task </Typography> }
        </>
      ) : 
       (
         <>
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <CircularProgress />
          </Box>
        </>
       )
      }
    </>
  )
}

export default ActivityResourcesTable;