//resources
export const fetch_all_resources = 'fetch_all_resources';
export const create_resource = 'create_resource';
export const update_resource = 'update_resource';
export const delete_resource = 'delete_resource';
export const fetch_resource_by_id = 'fetch_resource_by_id';
export const clear_current_resource = 'clear_current_resource';

//activities
export const fetch_all_activities = 'fetch_all_activities';
export const create_activity = 'create_activity';
export const update_activity = 'update_activity';
export const delete_activity = 'delete_activity';
export const fetch_activity_by_id = 'fetch_activity_by_id';
export const clear_current_activity = 'clear_current_activity';

//snackbar
export const snackbar_success = 'snackbar_success';
export const snackbar_error = 'snackbar_error';
export const snackbar_warning = 'snackbar_warning';
export const snackbar_clear = 'snackbar_clear';
